<template>
<div class="p-4">
      <router-view></router-view>
</div>

</template>

<script>
export default {

}
</script>

<style>

</style>